/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
		$("a[href^=#]").click(function(e) { e.preventDefault(); var dest = $(this).attr('href'); console.log(dest); $('html,body').animate({ scrollTop: $(dest).offset().top }, 'slow'); });
		$('header nav').meanmenu();
		$('.equalheight').responsiveEqualHeightGrid();
(function(){
        var gform = $(document).find('.gform_wrapper').attr('class');
        if(typeof gform !== 'undefined' && gform !== 'false'){
            $(document).on('gform_post_render',function(){
                var form = $('.gform_wrapper');
                var required = $('.gfield_contains_required');
                var controlGroup = $('.gfield');
                required.each(function(){
                    $(this).find('input, textarea, select').not('input[type="checkbox"], input[type="radio"]').attr('required', 'true');
                });
                $('.gform_fields').each(function(){
                    $(this).addClass('row');
                });
                controlGroup.each(function(){
                    $(this).addClass('form-group').find('input, textarea, select').not('input[type="checkbox"], input[type="radio"]').after('<span class="help-block"></span>').addClass('form-control');
                });
                form.find("input[type='submit'], input[type='button']").addClass('btn btn-primary').end().find('.gfield_error').removeClass('gfield_error').addClass('has-error');
                $('.gfield_checkbox, .gfield_radio').find('input[type="checkbox"], input[type="radio"]').each(function(){
                    var sib = $(this).siblings('label');
                    $(this).prependTo(sib);
                }).end().each(function(){
                    $(this).after('<span class="help-block"></span>');
                    if($(this).is('.gfield_checkbox')){
                        $(this).addClass('checkbox');
                    } else {
                        $(this).addClass('radio');
                    }
                });
                $('.validation_message').each(function(){
                    var sib = $(this).prev().find('.help-block');
                    $(this).appendTo(sib);
                });
                $('.validation_error').addClass('alert alert-danger');
                $('.gf_progressbar').addClass('progress progress-striped active').children('.gf_progressbar_percentage').addClass('progress-bar progress-bar-success');
            });
        } else {
            console.log('no forms were found');
            return false;
        }
    })();

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
